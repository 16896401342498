<template>
  <v-btn
    class="group leading-none transition"
    v-bind="$props"
  >
    <span class="inline-flex gap-sm justify-between items-center">
      <slot />
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        viewBox="0 0 16 16"
      >
        <path
          d="M12 8H4"
          class="origin-center scale-x-0 transition-transform group-hover:scale-x-[10px]"
        />
        <path
          d="m6.5 11.5 3.146-3.146a.5.5 0 0 0 0-.708L6.5 4.5"
          class="transition-transform group-hover:translate-x-[3px]"
        />
      </svg>
    </span>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VBtn } from "vuetify/components";

export default defineComponent({
  name: "VArrowBtn",
  extends: VBtn,
});
</script>
